import { Drawer } from 'antd';
import { useContext } from 'react';
import { DatadogMonitorContext } from '../../context/datadogMonitor.context';
import MonitorDrawerContent from './MonitorDrawerContent';

const MonitorUsageDrawer = () => {
    const { isOpenInfo, setIsOpenInfo } = useContext(DatadogMonitorContext);

    return (
        <Drawer
            width="60%"
            title="¿Cómo interpretar los graficos?"
            visible={isOpenInfo}
            placement="right"
            onClose={() => setIsOpenInfo(false)}
        >
            <MonitorDrawerContent />
        </Drawer>
    );
};

export default MonitorUsageDrawer;
