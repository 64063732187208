import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';

export const getExpressDeliveries = ({
    limit, skip, status, startDate, endDate, workspaceID, workspaceUniqueID,
}: any) => {
    const params = new URLSearchParams({
        limit: limit.toString(),
        skip: skip.toString(),
    });

    (status && status.length) && params.append('status', JSON.stringify(status));
    (startDate && startDate.length) && params.append('startDate', startDate.toString());
    (endDate && endDate.length) && params.append('endDate', endDate.toString());
    (workspaceID && workspaceID.length) && params.append('workspaceID', workspaceID);
    (workspaceUniqueID && workspaceUniqueID.length) && params.append('workspaceUniqueID', workspaceUniqueID);

    const url = `${OCTOPUS_BASE_URL}/express-deliveries`;
    const response = axios.get(url, { params });
    return response;
};

export const cancelExpressDelivery = (id: string) => {
    const url = `${OCTOPUS_BASE_URL}/express-deliveries/${id}/cancel`;
    const response = axios.put(url);
    return response;
};

export const getExpressDelivery = (expressDeliveryID: string) => {
    const url = `${OCTOPUS_BASE_URL}/express-deliveries/${expressDeliveryID}`;
    const response = axios.get(url);
    return response;
};

export const getGuides = ({
    limit,
    skip,
    status,
    startDate,
    endDate,
    businessID,
}: any) => {
    const params = new URLSearchParams({
        limit: limit.toString(),
        skip: skip.toString(),
    });

    (status && status.length) && params.append('status', JSON.stringify(status));
    (startDate && startDate.length) && params.append('startDate', startDate.toString());
    (endDate && endDate.length) && params.append('endDate', endDate.toString());
    (businessID && businessID.length) && params.append('businessID', businessID.toString());

    const url = `${OCTOPUS_BASE_URL}/guides`;
    const response = axios.get(url, { params });
    return response;
};

export const getGuide = (guideID: string) => {
    const url = `${OCTOPUS_BASE_URL}/guides/${guideID}`;
    const response = axios.get(url);
    return response;
};

export const getBusinessesWithED = () => {
    const url = `${OCTOPUS_BASE_URL}/express-deliveries/group?groupBy=BUSINESS`;
    const response = axios.get(url);
    return response;
};

export const startSearchManual = (businesses: string[]) => {
    const url = `${OCTOPUS_BASE_URL}/express-delivery-orchestrator/start-search`;
    const response = axios.post(url, { businesses });
    return response;
};

export const getGuideOrders = (guideID: string) => {
    const url = `${OCTOPUS_BASE_URL}/guides/${guideID}/orders`;
    const response = axios.get(url);
    return response;
};

export const cancelGuide = (guideID: string) => {
    const url = `${OCTOPUS_BASE_URL}/guides/${guideID}/cancel`;
    const response = axios.put(url);
    return response;
};
