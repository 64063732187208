import { PageHeader, Tabs } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ExpressDeliveryProvider } from '../components/ExpressDelivery/context/express-delivery.context';
import { DeliveriesTable, DeliveryDetail } from '../components/ExpressDelivery/components';
import { GuidesProvider } from '../components/Guides/context/guides.context';
import GuidesTable from '../components/Guides/components/Table';
import GuideDetail from '../components/Guides/components/GuideDetail';

const ExpressDeliveries = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const currentTab = searchParams.get('tab') || 'eds';

    const onTabChange = (key: string) => setSearchParams({ ...Object.fromEntries(searchParams), tab: key });

    useEffect(() => {
        if (!searchParams.get('tab')) {
            setSearchParams({ tab: 'eds' });
        }
    }, [searchParams]);

    const items = [
        {
            key: 'eds',
            label: 'Express Deliveries',
            children: (
                <div style={{ marginTop: 24 }}>
                    <DeliveriesTable />
                </div>
            ),
        },
        {
            key: 'routes',
            label: 'Routes',
            children: (
                <div style={{ marginTop: 24 }}>
                    <GuidesTable />
                </div>
            ),
        },
    ];

    return (
        <div>
            <GuidesProvider>
                <ExpressDeliveryProvider>
                    <DeliveryDetail />
                    <GuideDetail />
                    <PageHeader
                        title="Express Delivery"
                        style={{ padding: '32px 24px 24px 0' }}
                        footer={(
                            <Tabs
                                onChange={onTabChange}
                                defaultActiveKey={currentTab}
                                items={items}
                            />
                        )}
                    />
                </ExpressDeliveryProvider>
            </GuidesProvider>
        </div>
    );
};

export default ExpressDeliveries;
