import { useEffect, useMemo, useState } from 'react';
import {
    Col,
    Row,
    Typography,
    Modal,
    notification,
    Input,
    Space,
    Button,
    Tag,
} from 'antd';
import moment from 'moment';
import { RootStateOrAny, useSelector } from 'react-redux';

import { AiFillEdit } from 'react-icons/ai';
import CardDataBase from '../../../CardDataBase';
import { getWorkspaceStatus } from '../../utils/workspace';
import { EWorkspaceStatus, EWorkspaceStatusText } from '../../../../types/workspaces';
import { Plan } from '../../../../interfaces/plan';
import { editWorkspace } from '../../../../api/workspaces';
import PlanSelector from './PlanSelector';
import PaymentSelector from './PaymentSelector';

const { Text } = Typography;

interface IInformationDetail {
  createdAt: string;
  ownerEmail: string;
  currency: string;
  country: string;
  payment?: {
    paymentGateway?: string;
    paymentMethod?: string;
  };
  isDeleted: boolean;
  isSuspended: boolean;
  suspendedMessage: string;
  plan: string;
  workspaceId: string;
  deletedAt: string
  name: string;
}

const InformationDetail = ({
    createdAt,
    ownerEmail,
    currency,
    country,
    payment,
    isDeleted,
    isSuspended,
    suspendedMessage,
    plan,
    workspaceId,
    deletedAt,
    name,
}: IInformationDetail) => {
    const status = useMemo(
        () => getWorkspaceStatus({ isDeleted, isSuspended }),
        [isDeleted, isSuspended],
    );
    const [planActive, setPlanActive] = useState<Plan>();
    const { plans } = useSelector((state: RootStateOrAny) => state.plans);
    const [editing, setEditing] = useState(false);
    const [nameWS, setNameWS] = useState(name);

    const returnTagStyle = (status: string) => {
        switch (status) {
        case EWorkspaceStatus.ACTIVE: return '#41971d';
        case EWorkspaceStatus.DELETED: return 'red';
        case EWorkspaceStatus.SUSPENDED: return 'yellow';
        case EWorkspaceStatus.DELETED_SUSPENDED: return 'red';
        default: return 'green';
        }
    };

    const confirmChangePlan = (plan: Plan) => Modal.confirm({
        title: 'Change plan',
        content: `Are you sure you want to change the plan to ${plan.name}?`,
        onOk: async () => {
            try {
                await editWorkspace(workspaceId, { accountingPlan: plan._id });
                setPlanActive(plan);
                notification.success({
                    message: 'Success',
                    description: 'Plan changed successfully',
                });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    const handleChange = (e: any) => setNameWS(e.target.value);

    const handleClick = () => setEditing(true);

    const finishEditing = () => {
        setEditing(false);

        editWorkspace(workspaceId, { name: nameWS })
            .then(({ data }) => {
                notification.success({
                    message: data.message,
                });
            })
            .catch((err) => {
                console.log(err);
                notification.error({ message: 'oops! An error has occurred' });
            })
            .finally(() => {
                setNameWS(nameWS);
                setInterval(() => {
                    window.location.reload();
                }, 1500);
            });
    };

    const CARD_CONTENT = [
        {
            title: 'Name',
            content: (
                editing ? (
                    <Input
                        value={nameWS}
                        onChange={handleChange}
                        onBlur={finishEditing}
                        onPressEnter={finishEditing}
                        autoFocus
                    />
                ) : (
                    <Space size={16}>
                        <Text>
                            {nameWS}
                        </Text>
                        <Button
                            type="link"
                            size="small"
                            icon={(
                                <AiFillEdit
                                    style={{ verticalAlign: 'middle' }}
                                    size={16}
                                />
                            )}
                            onClick={handleClick}
                            style={{ padding: 0 }}
                        />
                    </Space>
                )
            ),
        },
        {
            title: 'Status',
            content: (
                status && (
                    <Space direction="vertical" size={8}>
                        <Tag color={returnTagStyle(status)}>
                            {EWorkspaceStatusText[status]}
                        </Tag>
                        {
                            isDeleted && (
                                <Tag color="red">
                                    {moment(deletedAt).format('HH:mm - DD MMM YYYY')}
                                </Tag>
                            )
                        }
                        {
                            (isSuspended && suspendedMessage) && (
                                <Text strong style={{ fontStyle: 'italic' }}>
                                    {suspendedMessage}
                                </Text>
                            )
                        }
                    </Space>
                )
            ),
        },
        {
            title: 'Created at',
            content: moment(createdAt).format('ddd DD MMM YYYY HH:mm'),
        },
        {
            title: 'Email',
            content: ownerEmail,
        },
        {
            title: 'Currency',
            content: currency,
        },
        {
            title: 'Country',
            content: country,
        },
        {
            title: 'Plan',
            content: (
                <PlanSelector
                    plan={planActive as Plan}
                    changePlan={confirmChangePlan}
                />
            ),
        },
        {
            title: 'Payment method',
            content: (
                <PaymentSelector
                    workspaceId={workspaceId}
                    payment={payment}
                />
            ),
        },
        {
            title: 'Gateway',
            content: (
                <Text>
                    { payment?.paymentGateway || 'Not registered' }
                </Text>
            ),
        },
    ];

    useEffect(() => {
        if (!plan) return;
        const activePlan = plans.find((p: Plan) => p._id === plan);
        if (!activePlan) return;
        setPlanActive(activePlan);
    }, [plan, plans]);

    useEffect(() => {
        setNameWS(name);
    }, [name]);

    return (
        <CardDataBase title="Information" style={{ minHeight: 345 }}>
            {
                CARD_CONTENT.map((data) => (
                    <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                        <Col>
                            <Text strong>
                                {data.title}
                                :
                            </Text>
                        </Col>
                        <Col>{data.content}</Col>
                    </Row>
                ))
            }
        </CardDataBase>
    );
};

export default InformationDetail;
