import {
    Col, Modal, Row, Space, Table,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { ExpressDeliveryContext } from '../context/express-delivery.context';

import InitialColumns from './initialColumns';
import Filters from './Filters';
import CountdownTimer from './CountdownTimer';
import StartSearch from './StartSearch';

interface ItemProps {
    _id: string;
    name: string;
    total: number;
}

const DeliveriesTable = () => {
    const INITIAL_COLUMNS: any = InitialColumns();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [items, setItems] = useState<ItemProps[]>([]);

    const {
        loading, total, paginator, expressDeliveries, onPageChange, fetchExpressDeliveries,
        startDate, endDate, handleStatus, handleDate, handleWorkspaceUniqueID, searchParams,
        fetchBusinessWithED,
    } = useContext(ExpressDeliveryContext);

    const { limit, skip } = paginator;

    const statusString = searchParams.get('status');
    const workspaceUniqueID = searchParams.get('workspaceUniqueID');
    const currentTab = searchParams.get('tab');

    const handleStartSearch = async () => {
        const items: any = await fetchBusinessWithED();
        setItems(items);
        setOpenModal(true);
    };

    useEffect(() => {
        if (currentTab !== 'eds') return;
        fetchExpressDeliveries();
    }, [paginator, startDate, endDate, statusString, workspaceUniqueID, currentTab]);

    return (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
            {
                openModal && (
                    <StartSearch
                        open={openModal}
                        items={items}
                        onClose={() => setOpenModal(false)}
                    />
                )
            }
            <Row justify="space-between" align="middle">
                <Col>
                    <CountdownTimer />
                </Col>
                <Col>
                    <Filters
                        searchParams={searchParams}
                        startDate={startDate}
                        endDate={endDate}
                        handleStatus={handleStatus}
                        handleDate={handleDate}
                        handleWorkspaceUniqueID={handleWorkspaceUniqueID}
                        handleStartSearch={handleStartSearch}
                    />
                </Col>
            </Row>
            <Table
                loading={loading}
                columns={INITIAL_COLUMNS}
                dataSource={expressDeliveries}
                pagination={{
                    total,
                    current: skip / limit + 1,
                    pageSize: limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 1500 }}
                rowKey="_id"
            />
        </Space>
    );
};

export default DeliveriesTable;
