import { useContext } from 'react';
import { Button, Space } from 'antd';
import { FaQuestionCircle } from 'react-icons/fa';
import { DatadogMonitorContext } from '../context/datadogMonitor.context';

const UsageButton = () => {
    const { setIsOpenInfo } = useContext(DatadogMonitorContext);

    return (
        <Button
            type="link"
            onClick={
                () => setIsOpenInfo(true)
            }
            className="flex-row center-row-center"
        >
            ¿Cómo interpretar los graficos?
            <FaQuestionCircle style={{ marginLeft: 4 }} />
        </Button>
    );
};

export default UsageButton;
