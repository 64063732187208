import { message } from 'antd';
import {
    FC, createContext, useCallback, useEffect, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { IContext } from './summary.interface';
import { initialContext } from './summary.initial';
import { ESearchType } from './summary.enum';
import { getWorkspaces } from '../../../api/workspaces';
import { getBookings } from '../../../api/booking';
import useDebounce from '../../../hooks/useDebounce';

export const SummaryContext = createContext<IContext>(initialContext);

export const SummaryProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [results, setResults] = useState<any[]>(initialContext.results);
    const [total, setTotal] = useState<number>(0);
    const [limit] = useState<number>(10);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams();

    const search = searchParams.get('search') || '';
    const searchType = searchParams.get('searchType') || ESearchType.WORKSPACE;

    const debouncedSearch = useDebounce<string>(search, 500);

    const handleSearchWorkspaces = useCallback((search?: string) => {
        const PAGE = Math.ceil(results.length / limit);

        const params: any = {
            limit,
            page: PAGE + 1,
        };

        (search && search !== '') && (params.search = search);

        if (!hasNextPage) return;

        return getWorkspaces({ paginator: params })
            .then(({ data }: any) => {
                setResults([...results, ...data.data]);
                setTotal(data.meta.itemCount);
                setHasNextPage(data.meta.hasNextPage);
            })
            .catch((error: any) => {
                message.error(`Error fetching workspaces ${error?.message}`);
            });
    }, [results, limit, hasNextPage]);

    const handleSearchBookings = useCallback((searchValue?: string) => {
        const PAGE = Math.ceil(results.length / limit);

        const isBookingId = searchValue && parseInt(searchValue, 10);

        const params: any = {
            limit,
            skip: isBookingId ? 0 : PAGE,
        };

        return getBookings(params, searchValue)
            .then(({ data }: any) => {
                setResults(isBookingId ? data.data.bookingData : [...results, ...data.data.bookingData]);
                setTotal(data.data.dataCount);
            })
            .catch((error: any) => {
                message.error(`Error fetching bookings ${error?.message}`);
            });
    }, [results, limit]);

    const getRequest = async (value: string) => {
        switch (searchType) {
        case ESearchType.WORKSPACE:
            await handleSearchWorkspaces(value);
            break;
        case ESearchType.BOOKING:
            await handleSearchBookings(value);
            break;
        default:
            message.error('Invalid search type');
            break;
        }
    };

    const fetchResults = async (value?: string) => {
        setLoading(true);
        try {
            await getRequest(value || search);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (value: string) => {
        setSearchParams({
            searchType,
            search: value,
        });
        setHasNextPage(true);
    };

    useEffect(() => {
        setResults([]);
        if (!debouncedSearch || debouncedSearch === '') return;
        fetchResults();
    }, [debouncedSearch]);

    return (
        <SummaryContext.Provider
            value={{
                loading,
                results,
                searchParams,
                setSearchParams,
                setResults,
                handleSearch,
                total,
                fetchResults,
            }}
        >
            {children}
        </SummaryContext.Provider>
    );
};
