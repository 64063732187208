import moment from 'moment';
import { IGuide } from '../../../interfaces/express-delivery.interface';

export const initialContext = {
    loading: false,
    loadingAction: false,
    data: [] as IGuide[],
    itemSelected: null,
    total: 0,
    paginator: {
        limit: 10,
        skip: 0,
    },
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    tab: null,
    searchParams: {},
    handleStatus: () => { },
    handleDate: () => { },
    setLoading: () => {},
    fetchData: () => {},
    onPageChange: () => {},
    setItemSelected: () => {},
};
