import axios from 'axios';
import { PaginatorType } from '../types/paginator';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SMR_URL = process.env.REACT_APP_SMR_URL;
export const EVENTS_BASE_URL = process.env.REACT_APP_EVENTS_BASE_URL;
export const SEARCH_ORCHESTRATOR_BASE_URL = process.env.REACT_APP_SEARCH_ORCHESTRATOR_BASE_URL;
export const OCTOPUS_BASE_URL = process.env.REACT_APP_OCTOPUS_BASE_URL;
export const ORDER_BASE_URL = process.env.REACT_APP_ORDER_BASE_URL;
export const PUBLIC_BOOKING_URL = process.env.REACT_APP_PUBLIC_BOOKING_URL;

export const getBookings = (paginator: PaginatorType, search?: string, currentStatus?: number[]) => {
    const query: any = { ...paginator };
    if (search) {
        query.searchBooking = search;
    }
    if (currentStatus?.length) {
        query.currentStatus = `[${currentStatus.toString()}]`;
    }
    const urlParams = new URLSearchParams(query);
    return axios(`${BASE_URL}/admin/bookings?${urlParams.toString()}&typeText=CORPORATE`);
};

export const getBookingDetails = (booking: string) => axios(`${BASE_URL}/admin/getBookingInfo?bookingID=${booking}`);

export const getBookingEvents = (bookingId: string) => axios(`${EVENTS_BASE_URL}/api/bookings/${bookingId}`);

export const getBookingOrderUpdates = (bookingId: string) => axios(`${ORDER_BASE_URL}/orders/?bookingID=${bookingId}`);

export const updateBooking = (booking: Object) => axios.put(`${BASE_URL}/admin/setProviderData`, booking);

export const getWarningBookings = (paginator?: any) => {
    const urlParams = new URLSearchParams(paginator);
    return axios.get(`${BASE_URL}/admin/warningBookings?${urlParams.toString()}`);
};

export const getUserBooking = (paginator: PaginatorType, type: string, driver?: string) => {
    const query: any = { ...paginator, [type]: driver };
    const urlParams = new URLSearchParams(query);
    return axios.get(`${BASE_URL}/admin/userBookings?${urlParams.toString()}`);
};

export const setBookingIssue = (bookingID: string, bookingIssuesIds: any) => axios.put(`${BASE_URL}/booking/setBookingIssue`, {
    bookingID,
    bookingIssuesIds,
});

export const getBookingIssue = () => axios.get(`${BASE_URL}/bookingIssues/getBookingIssues`);

export const registerBookingIssue = ({ name, details }: any) => axios.post(`${BASE_URL}/bookingIssues/registerBookingIssue`, {
    name,
    details,
    key: name.toUpperCase(),
});

export const updatePriceBreakup = (bookingID: string, priceBreakup: any) => axios.patch(
    `${OCTOPUS_BASE_URL}/booking/${bookingID}/price-break-up`,
    priceBreakup,
);

export const cancelBooking = ({ bookingID, ...payload }: any) => (
    axios.post(`${OCTOPUS_BASE_URL}/booking/${bookingID}/cancel`, { ...payload })
);

export const assignResponsable = (bookingID: string, userID: string) => axios.put(`${BASE_URL}/admin/assignResponsable`, { bookingID, userID });

export const changePaymentMethod = (bookingID: string, paymentMethod: string) => axios.put(
    `${BASE_URL}/booking/changePaymentMethod`,
    { bookingID, paymentMethod },
);

export const startSearch = (bookingID: string) => axios.post(`${OCTOPUS_BASE_URL}/booking/${bookingID}/start-search`);

export const getBookingRoute = (bookingID: string) => axios.get(`${BASE_URL}/booking/getBookingRoute?bookingID=${bookingID}&objectId=false`);

export const getBookingRequests = (bookingID: string) => axios.get(`${OCTOPUS_BASE_URL}/booking/${bookingID}/requests`);

export const getOrderResponse = (orderID: string) => axios.get(`${OCTOPUS_BASE_URL}/orders/${orderID}/response`);

export const getOrderRequest = (orderID: string) => axios.get(`${OCTOPUS_BASE_URL}/orders/${orderID}/request`);

export const getReview = (bookingID: string) => axios.get(`${OCTOPUS_BASE_URL}/booking/${bookingID}/review`);

export const updateStatusUpdates = (bookingID: string, statusUpdates: any) => axios.put(
    `${OCTOPUS_BASE_URL}/booking/${bookingID}/status-updates`,
    statusUpdates,
);

export const getBookingDriverDetails = (bookingID: string) => axios.get(`${OCTOPUS_BASE_URL}/booking/${bookingID}/driverDetails`);
