import {
    Modal,
    Form,
    Input,
    Select,
    Button,
    Row,
    Col,
    Tag,
    Divider,
    Typography,
    Space,
} from 'antd';
import { BsPlusCircle, BsTrash } from 'react-icons/bs';
import { useState, useEffect, useContext } from 'react';
import { addConcept, updateConcept } from '../../../api/payments';
import { VariableContext } from '../../Plans/context/VariableContext';

interface IConceptModal {
  visible: boolean;
  concept: any;
  onCancel: (concept: any) => void;
}

const { Title, Text } = Typography;

const ConceptModal = ({
    visible,
    concept,
    onCancel,
}: IConceptModal) => {
    const [form] = Form.useForm<{ operation: string; conditions: any[] }>();
    const [loading, setLoading] = useState(false);
    const {
        variables,
    } = useContext(VariableContext);
    const operation = Form.useWatch('operation', form);
    const frequency = Form.useWatch('frequency', form);

    const handleOk = () => {
        setLoading(true);
        const values = form.getFieldsValue();
        const conditions = values.conditions.filter((condition: any) => condition?.field && condition?.operator);
        if (concept._id) {
            updateConcept(concept._id, { ...values, conditions, planID: concept.planID })
                .then(({ data }) => {
                    form.resetFields();
                    onCancel(data.data);
                })
                .catch()
                .finally(() => setLoading(false));
        } else {
            addConcept({ ...values, conditions, planID: concept.planID })
                .then(({ data }) => {
                    form.resetFields();
                    onCancel(data.data);
                })
                .catch()
                .finally(() => setLoading(false));
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel(null);
    };

    useEffect(() => {
        if (!concept) return;
        form.setFieldsValue(concept);
    }, [concept, form]);

    const options = [
        {
            label: 'Equals',
            value: 'EQUALS',
        },
        {
            label: 'Not Equals',
            value: 'NOT_EQUALS',
        },
        {
            label: 'Greater Than',
            value: 'GREATER_THAN',
        },
        {
            label: 'Less Than',
            value: 'LESS_THAN',
        },
        {
            label: 'Greater Than or Equals',
            value: 'GREATER_THAN_OR_EQUALS',
        },
        {
            label: 'Less Than or Equals',
            value: 'LESS_THAN_OR_EQUALS',
        },
        {
            label: 'Contains',
            value: 'CONTAINS',
        },
        {
            label: 'Not Contains',
            value: 'NOT_CONTAINS',
        },
    ];

    const fieldOptions = [
        {
            label: 'Billed Distance',
            value: 'distanceBreakUp.billedDistance',
        },
        {
            label: 'BillingMethod',
            value: 'billingMethod',
        },
        {
            label: 'Business Order Amount',
            value: 'priceBreakUp.businessOrderAmount',
        },
        {
            label: 'Booking Numeric Id',
            value: 'bookingNumericId',
        },
        {
            label: 'Business Name',
            value: 'businessName',
        },
        {
            label: 'Car Name',
            value: 'carName',
        },
        {
            label: 'Cook Time',
            value: 'cookTime',
        },
        {
            label: 'Country',
            value: 'country',
        },
        {
            label: 'Currency',
            value: 'currency',
        },
        {
            label: 'Customer Mobile',
            value: 'customerMobile',
        },
        {
            label: 'Customer Name',
            value: 'customerName',
        },
        {
            label: 'Delivery City',
            value: 'deliveryCity',
        },
        {
            label: 'Delivery Provider',
            value: 'deliveryProvider',
        },
        {
            label: 'Exact Distance',
            value: 'distanceBreakUp.exactDistance',
        },
        {
            label: 'Extra Distance',
            value: 'distanceBreakUp.extraDistance',
        },
        {
            label: 'Notifications',
            value: 'smrSentVia',
        },
        {
            label: 'Origin',
            value: 'origin',
        },
        {
            label: 'Order Amount',
            value: 'orderAmount',
        },
        {
            label: 'Payment Method',
            value: 'paymentMethod',
        },
        {
            label: 'Provider Fee',
            value: 'priceBreakUp.providerFee',
        },
        {
            label: 'Restart By Business',
            value: 'counters.restartByBusiness',
        },
        {
            label: 'Restart By Business With Penalty',
            value: 'counters.restartByBusinessWithPenalty',
        },
        {
            label: 'Status',
            value: 'statusText',
        },
        {
            label: 'Service Amount',
            value: 'ServiceAmount',
        },
        {
            label: 'Real Distance',
            value: 'distanceBreakUp.realDistance',
        },
        {
            label: 'Requires Proof Of Delivery',
            value: 'requiresProofOfDelivery',
        },
        {
            label: 'Used Google Address',
            value: 'usedGoogleAddress',
        },
    ];

    const bookingVariables = [
        {
            _id: 'distanceBreakUp.billedDistance',
            name: 'Billed Distance',
            value: 'distanceBreakUp.billedDistance',
        },
        {
            _id: 'priceBreakUp.businessOrderAmount',
            name: 'Business Order Amount',
            value: 'priceBreakUp.businessOrderAmount',
        },
        {
            _id: 'deliveryProvider',
            name: 'Delivery Provider',
            value: 'deliveryProvider',
        },
        {
            _id: 'distanceBreakUp.exactDistance',
            name: 'Exact Distance',
            value: 'distanceBreakUp.exactDistance',
        },
        {
            _id: 'distanceBreakUp.extraDistance',
            name: 'Extra Distance',
            value: 'distanceBreakUp.extraDistance',
        },
        {
            _id: 'priceBreakUp.providerFee',
            name: 'Provider Fee',
            value: 'priceBreakUp.providerFee',
        },
        {
            _id: 'country',
            name: 'Country',
            value: 'country',
        },
        {
            _id: 'orderAmount',
            name: 'Order Amount',
            value: 'orderAmount',
        },
        {
            _id: 'counters.restartByBusiness',
            name: 'Restart By Business',
            value: 'counters.restartByBusiness',
        },
        {
            _id: 'counters.restartByBusinessWithPenalty',
            name: 'Restart By Business With Penalty',
            value: 'counters.restartByBusinessWithPenalty',
        },
        {
            _id: 'priceBreakUp.restartPenaltyFee',
            name: 'Restart Penalty Fee',
            value: 'priceBreakUp.restartPenaltyFee',
        },
    ];

    const workspaceVariables = [
        {
            _id: 'businesses',
            name: 'Businesses',
            value: 'businesses',
        },
    ];

    const handleVariableClick = (type: string, variable: any) => {
        const oldOperation = form.getFieldValue('operation') || '';
        let newOperation = `${oldOperation} {{${type}.${variable._id}}}`;
        const input = document.getElementById('operation');
        // @ts-ignore
        const [start, end] = [input.selectionStart, input.selectionEnd];
        if (Number.isInteger(start) && Number.isInteger(end)) {
            newOperation = `${oldOperation.substring(0, start)}{{${type}.${variable._id}}}${oldOperation.substring(end, oldOperation.length)}`;
        }
        form.setFieldsValue({
            operation: newOperation,
        });
    };

    const formatOperation = (operation = '') => {
        const variablesExp = operation.match(/{{variable.(\w+)}}/g);
        let formattedOperation = operation;
        if (variablesExp) {
            variablesExp.forEach((variable) => {
                const id = variable.replace('{{variable.', '').replace('}}', '');
                const variableObj = variables.find((v: any) => v._id === id);
                if (variableObj) {
                    formattedOperation = formattedOperation.replace(variable, `<span style="color: #87d068">${variableObj.name}</span>`);
                }
            });
        }
        const bookingVariablesExp = operation.match(/{{booking.(\w+(?:\.\w+)*)}}/g);
        if (bookingVariablesExp) {
            bookingVariablesExp.forEach((variable) => {
                const id = variable.replace('{{booking.', '').replace('}}', '');
                const variableObj = bookingVariables.find((v: any) => v._id === id);
                if (variableObj) {
                    formattedOperation = formattedOperation.replace(variable, `<span style="color: #2db7f5">${variableObj.name}</span>`);
                }
            });
        }
        const workspaceVariablesExp = operation.match(/{{workspace.(\w+(?:\.\w+)*)}}/g);
        if (workspaceVariablesExp) {
            workspaceVariablesExp.forEach((variable) => {
                const id = variable.replace('{{workspace.', '').replace('}}', '');
                const variableObj = workspaceVariables.find((v: any) => v._id === id);
                if (variableObj) {
                    formattedOperation = formattedOperation.replace(variable, `<span style="color: #2db7f5">${variableObj.name}</span>`);
                }
            });
        }
        return formattedOperation;
    };

    return (
        <Modal
            title={concept?.name || 'Create Concept'}
            visible={visible}
            width={1000}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Crear
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item label="Name" name="name">
                    <Input placeholder="Concept" />
                </Form.Item>
                <Form.Item label="Frequency" name="frequency">
                    <Select placeholder="Select a frequency">
                        <Select.Option value="UNIQUE">Unique</Select.Option>
                        <Select.Option value="WEEKLY">Weekly (7 days)</Select.Option>
                        <Select.Option value="FORTNIGHTLY">Fortnightly (15 days)</Select.Option>
                        <Select.Option value="MONTHLY">Monthly (30 days)</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Conditions">
                    <Form.List name="conditions" initialValue={[]}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field) => (
                                    <Row key={field.key} gutter={3}>
                                        <Col span={7}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'field']}
                                                rules={[{ required: true, message: 'Missing field' }]}
                                            >
                                                <Select
                                                    showSearch
                                                    options={fieldOptions}
                                                    placeholder="Field"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'operator']}
                                                rules={[{ required: true, message: 'Missing operator' }]}

                                            >
                                                <Select placeholder="Select a operator" options={options} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'value']}
                                                rules={[{ required: true, message: 'Missing value' }]}
                                            >
                                                <Input placeholder="10.0" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Button danger onClick={() => remove(field.name)}>
                                                <span className="anticon">
                                                    <BsTrash size={17} />
                                                </span>
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<span className="anticon"><BsPlusCircle /></span>}
                                    >
                                        Add Condition
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item label="Result">
                    <div
                        dangerouslySetInnerHTML={{ __html: formatOperation(operation) }}
                    />
                </Form.Item>
                <Form.Item label="Operation" name="operation">
                    <Input placeholder="10.0" />
                </Form.Item>
                {frequency === 'MONTHLY' && (
                    <>
                        <Divider orientation="left">Workspace</Divider>
                        {workspaceVariables.map((variable) => (
                            <Tag onClick={() => handleVariableClick('workspace', variable)} key={variable._id} color="#2db7f5">
                                {variable.name}
                            </Tag>
                        ))}
                    </>
                )}
                {frequency === 'UNIQUE' && (
                    <>
                        <Divider orientation="left">Booking</Divider>
                        <Row justify="start" align="top" gutter={[4, 12]}>
                            {
                                bookingVariables.map((variable) => (
                                    <Col>
                                        <Tag
                                            color="#2db7f5"
                                            key={variable._id}
                                            onClick={() => handleVariableClick('booking', variable)}
                                        >
                                            <Text style={{ color: '#1A1F36' }} strong>
                                                {variable.name}
                                            </Text>
                                        </Tag>
                                    </Col>
                                ))
                            }
                        </Row>
                    </>
                )}
                <Divider orientation="left">Variables</Divider>
                <Space direction="vertical" size={16}>
                    <Space direction="vertical" size={8}>
                        <Space>
                            <Title level={5} style={{ margin: 0 }}>
                                Plan Variables:
                            </Title>
                            <Text>
                                Specific to the plan
                            </Text>
                        </Space>
                        <Row justify="start" align="top" gutter={[4, 12]}>
                            {
                                variables.map((variable) => variable.planID && (
                                    <Col>
                                        <Tag
                                            color="#C9D6E8"
                                            key={variable._id}
                                            onClick={() => handleVariableClick('variable', variable)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <Text style={{ color: '#1A1F36' }} strong>
                                                {variable.name}
                                            </Text>
                                        </Tag>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Space>
                    <Space direction="vertical" size={8}>
                        <Space>
                            <Title level={5} style={{ margin: 0 }}>
                                Global Variables:
                            </Title>
                            <Text>
                                Common to all plans
                            </Text>
                        </Space>
                        <Row justify="start" align="top" gutter={[4, 12]}>
                            {
                                variables.map((variable) => !variable.planID && (
                                    <Col>
                                        <Tag
                                            color="#87d068"
                                            key={variable._id}
                                            onClick={() => handleVariableClick('variable', variable)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <Text style={{ color: '#1A1F36' }} strong>
                                                {variable.name}
                                            </Text>
                                        </Tag>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Space>
                </Space>
            </Form>
        </Modal>
    );
};

export default ConceptModal;
