import { Table } from 'antd';
import InitialColumns, { ColumnProps } from './initialColumn';
import { IContext } from '../context/invoices.interface';

const InvoicesTable = ({
    loading,
    data,
    total,
    paginator,
    selectedRowKeys,
    setSelectedRowKeys,
    onPageChange,
}: Partial<IContext>) => {
    const INITIAL_COLUMNS: ColumnProps[] = InitialColumns();

    const { limit, page } = paginator || { limit: 0, skip: 0 };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys!(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Table
            rowSelection={rowSelection}
            loading={loading}
            columns={INITIAL_COLUMNS}
            dataSource={data}
            pagination={{
                total,
                current: page,
                pageSize: limit,
                onChange: onPageChange,
                showSizeChanger: true,
            }}
            bordered
            scroll={{ x: 800 }}
            rowKey="_id"
        />
    );
};

export default InvoicesTable;
