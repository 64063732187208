import { useEffect, useState } from 'react';
import {
    Button,
    Descriptions,
    Space,
    Spin,
    Typography,
} from 'antd';
import { getBookingDriverDetails } from '../../api/booking';

interface Props {
    bookingID: string;
}

interface IPublicPhone {
    publicPhone: string;
    pinCode: string;
}

const { Text } = Typography;

const BookingDriverDetails = ({ bookingID }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [phone, setPhone] = useState<IPublicPhone | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetchPublicPhone = async (bookingID: string) => {
        setLoading(true);
        getBookingDriverDetails(bookingID)
            .then(({ data }) => {
                setPhone(data.data);
                setError(null);
            })
            .catch((error) => {
                const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch public phone';
                setError(errorMessage);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!bookingID && !show) return;
        fetchPublicPhone(bookingID);
    }, [show]);

    if (loading) {
        return (
            <Space size={4}>
                <Spin size="small" />
                <Text>Getting driver public phone</Text>
            </Space>
        );
    }

    return (
        phone ? (
            error ? (
                <Text type="danger">{error}</Text>
            ) : (
                <Descriptions className="user-table" title="" size="small">
                    <Descriptions.Item span={3} label="Public Phone">{phone?.publicPhone}</Descriptions.Item>
                    <Descriptions.Item span={3} label="Pin Code">{phone?.pinCode}</Descriptions.Item>
                </Descriptions>
            )
        ) : (
            <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => setShow(true)}
            >
                Show Public Phone
            </Button>
        )
    );
};

export default BookingDriverDetails;
