import { useState } from 'react';
import { Modal, notification, Switch } from 'antd';
import { updateWorkspaceSettings } from '../../../../api/workspaces';
import { IWorkspace } from '../../../../types/workspaces';

interface Props {
    checked: boolean | undefined;
    configName: string;
    varName: string;
    workspace: IWorkspace | undefined;
    setWorkspace: (workspace: any) => void;
}

const ChangeConfig = ({
    workspace,
    checked,
    configName,
    varName,
    setWorkspace,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = async (value: boolean) => Modal.confirm({
        title: `Edit ${configName}`,
        content: `Are you sure you want to ${varName ? 'show' : 'hide'} the ${configName}?`,
        onOk: () => {
            setLoading(true);
            updateWorkspaceSettings(workspace?._id || '', { [varName]: value })
                .then(() => {
                    const workspaceUpdated: any = {
                        ...workspace,
                        workspaceSettings: {
                            ...workspace?.workspaceSettings,
                            [varName]: value,
                        },
                    };
                    setWorkspace(workspaceUpdated);
                    notification.success({
                        message: 'Success',
                        description: `${configName} changed successfully`,
                    });
                })
                .catch((error) => {
                    console.log(`${configName} error`, error);
                    notification.error({
                        message: 'Error',
                        description: 'Something went wrong, please try again later',
                    });
                })
                .finally(() => setLoading(false));
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    return (
        <Switch
            checked={checked}
            onChange={handleChange}
            loading={loading}
        />
    );
};

export default ChangeConfig;
