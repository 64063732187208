import {
    Col,
    PageHeader,
    Row,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { ConceptTable } from '../../components/Plans/ConceptTable';
import { ConceptProvider } from '../../components/Plans/context/ConceptContext';
import { VariableTable } from '../../components/Plans/VariableTable';
import { VariableProvider } from '../../components/Plans/context/VariableContext';

export const Concepts = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const plan = location.state;
    console.log('plan', plan);

    const onBackHandle = () => {
        if (location.key !== 'default') navigate(-1);
        else navigate('/v2/workspaces');
    };

    return (
        <ConceptProvider>
            <VariableProvider>
                <PageHeader
                    onBack={onBackHandle}
                    title={`Plan: ${plan?.name}`}
                    style={{ padding: '24px 0' }}
                />
                <Row gutter={[40, 16]}>
                    <Col xs={24}>
                        <ConceptTable />
                    </Col>
                    <Col xs={24}>
                        <VariableTable />
                    </Col>
                </Row>
            </VariableProvider>
        </ConceptProvider>
    );
};
