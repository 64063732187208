import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Avatar, Dropdown, Input, Menu, PageHeader, Table,
    Tooltip,
} from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { VscDebugDisconnect } from 'react-icons/vsc';
import { getAllBusiness } from '../api/business';
import { IBusinessTableData } from '../types/bussiness';
import { setSettingsTiersForBussines } from '../actions/modals';
import { BussinesTiersModal } from '../components/Modals/SettingsTiersBussines/SettingsTiersBussines';

const { Search } = Input;

const WarningServiceRequestsMenu = ({
    bussines,
}: any) => {
    const dispatch = useDispatch();
    const menu = [
        {
            children: 'Setting tiers',
            onClick: () => {
                dispatch(setSettingsTiersForBussines(bussines));
            },
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

const Business = () => {
    const { deliveries, loading: dpLoading } = useSelector((state: any) => state.deliveries);
    const [pagination, setPagination] = useState<any>({
        limit: 10,
        skip: 0,
    });

    const [business, setBusiness] = useState<IBusinessTableData[]>([]);
    const [loadingBusiness, setLoadingBusiness] = useState(false);
    const [totalBusiness, setTotalBusiness] = useState(0);

    const fetchBusiness = async () => {
        try {
            setLoadingBusiness(true);
            const { data: { data } } = await getAllBusiness(pagination);
            setBusiness(data.users);
            setTotalBusiness(data.count);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingBusiness(false);
        }
    };

    useEffect(() => {
        fetchBusiness();
    }, [pagination]);

    const columns = [
        {
            title: 'Unique Id',
            dataIndex: 'userUniqueID',
            key: 'userUniqueID',
            render: (userUniqueID: string, user: any) => <Link to={`/v2/business/${user._id}`}>{userUniqueID}</Link>,
        },
        {
            title: '',
            dataIndex: 'businessID',
            key: 'isDeleted',
            width: 40,
            render: (business: any) => (
                business.isDeleted ? (
                    <Tooltip title="Business is deactivated">
                        <VscDebugDisconnect color="red" size={25} />
                    </Tooltip>
                ) : null

            ),
        },
        {
            title: 'Name',
            dataIndex: 'businessID',
            key: 'businessID',
            render: (business: any) => business?.companyName,
        },
        {
            title: 'Contact Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, user: any) => `${name} ${user.lastName}`,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Delivery Providers',
            dataIndex: 'externalAPIConfiguration',
            key: 'externalAPIConfiguration',
            render: (externalAPIConfiguration: any) => {
                const { deliveryProviders } = externalAPIConfiguration;
                const activeDeliveryProviders = deliveryProviders.filter((dp: any) => dp.isActive);
                return (
                    <Avatar.Group>
                        {activeDeliveryProviders.map((dp: any) => {
                            const delivery = dpLoading ? {} : deliveries.find((delivery: any) => delivery.keyName === dp.keyName);
                            return (
                                <Avatar
                                    key={dp.keyName}
                                    style={{ borderColor: '#212845', backgroundColor: '#1a1f36' }}
                                    src={delivery?.image}
                                />
                            );
                        })}
                    </Avatar.Group>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'statusText',
            key: 'actions',
            width: 100,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (_statusText: any, record: any) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <WarningServiceRequestsMenu
                            bussines={record}
                            /* reload={handleReload} */
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any, a: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    const onSearch = async (query: string) => {
        setPagination((pagination: any) => {
            const paginationUpdated: any = { limit: pagination.limit, skip: 0 };
            if (query) paginationUpdated.searchUser = query;
            return paginationUpdated;
        });
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPagination((pagination: any) => ({ ...pagination, limit: pageSize, skip: pageSize * (page - 1) }));
    };

    return (
        <div>
            <PageHeader
                title="Business"
            />
            <div style={{ margin: '25px' }}>

                <Search
                    placeholder="Business name"
                    onSearch={onSearch}
                    style={{ width: 300 }}
                    allowClear
                />

                <Table
                    style={{ marginTop: 20 }}
                    loading={loadingBusiness}
                    columns={columns}
                    dataSource={business}
                    bordered
                    scroll={{ x: 500 }}
                    rowKey="_id"
                    rowClassName={(record: any) => (record?.businessID?.isDeleted && 'table-row-warning')}
                    pagination={{
                        total: totalBusiness,
                        current: (pagination.skip / pagination.limit) + 1,
                        pageSize: pagination.limit,
                        onChange: onPageChange,
                        showSizeChanger: true,
                    }}
                />
                <BussinesTiersModal />
            </div>
        </div>
    );
};

export default Business;
