import { Space, Tag, Typography } from 'antd';
import { BookingEvent } from '../../types/booking';

const { Text } = Typography;

const BookingEventCard = ({ eventData }: { eventData: BookingEvent }) => {
    const event = new BookingEvent(eventData);
    const {
        isAdmin,
        isEmulated,
        isBusiness,
        originRestart,
        type,
    } = event;

    return (

        <Space direction="vertical">
            <Text>
                {event.description}
            </Text>
            {
                event.registeredByText && (
                    <Space size={0} direction="vertical">
                        <Space size={8}>
                            <Text strong>
                                {'Registered by: ' }
                            </Text>
                            <Text>
                                {event.registeredByText || ''}
                            </Text>
                        </Space>
                        <Text strong style={{ fontSize: '12px', fontStyle: 'italic' }}>
                            {event.registeredBy?.email || ''}
                        </Text>
                    </Space>
                )
            }
            <Space size={4}>
                {
                    isEmulated && (
                        <Tag color="yellow">
                            Emulated
                        </Tag>
                    )
                }
                {
                    (originRestart === 'ADMIN' || isAdmin) && (type !== 'STATUS_UPDATE') && (
                        <Tag color="green">
                            By Admin
                        </Tag>
                    )
                }
                {
                    (originRestart === 'BUSINESS' || isBusiness) && (
                        <Tag color="blue">
                            By Business
                        </Tag>
                    )
                }
            </Space>
        </Space>
    );
};

export default BookingEventCard;
