/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import {
    Avatar, Dropdown, Menu, notification, Table, Tag, Tooltip, Space,
} from 'antd';
import moment from 'moment';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AiFillCamera, AiFillCloseCircle, AiOutlineFileWord } from 'react-icons/ai';
import { BsCash, BsCreditCard2Back, BsThreeDots } from 'react-icons/bs';
import { useDispatch } from 'react-redux';

import { getProofColor, getStatusChipClass, getProofTitle } from '../../utils/bookings';
import { PROVIDERS } from '../../const/providers';
import { BookingType } from '../../types/booking';
import {
    AssigningDriverManually, CancelService, ChangeStatusModal, RestartSearchModal, CommentModal,
} from '../Modals';
import { ReportIssue } from '../Modals/ReportIssue/ReportIssue';
import {
    openModal, setChangeStatus, setDriverManuallylId, setRestartSearchId, setRidesAddComment,
} from '../../actions/modals';
import { endSearch } from '../../api/deliveryProviders';
import { SMR_URL } from '../../api';
import { ModalTypes } from '../../types/modals';
import Filters from './Filters';

const BookingMenu = ({ booking }: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const menu = [
        {
            children: 'Assign Picker Manually',
            onClick: () => {
                dispatch(setDriverManuallylId(booking));
            },
        },
        {
            children: 'Change Status',
            onClick: () => {
                dispatch(setChangeStatus(booking));
            },
        },
        {
            children: 'End Search',
            onClick: () => {
                endSearch(booking._id, { isAdmin: true })
                    .then((res: any) => notification.success({
                        message: 'Success!',
                        description: `${res.data.message}`,
                    }))
                    .catch(() => notification.error({
                        message: 'Opps!',
                        description: 'An Error ocurred',
                    }));
            },
        },
        {
            children: 'Share My Ride',
            onClick: () => {
                window.open(`${SMR_URL}/${booking.smrToken}`);
            },
        },
        {
            children: 'Set Hack',
            onClick: () => {
                navigate(`/v2/booking?id=${booking._id}`);
            },
        },
        {
            children: 'Restart Search',
            onClick: () => {
                dispatch(setRestartSearchId(booking));
            },
        },
        {
            children: 'Cancel service',
            onClick: () => {
                dispatch(openModal(ModalTypes.CancelService, booking));
            },
        },
        {
            children: 'Report Issue',
            onClick: () => {
                dispatch(openModal(ModalTypes.ReportIssue, booking));
            },
        },
        {
            children: 'Add comment',
            onClick: () => {
                dispatch(setRidesAddComment(booking));
            },
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

const COLUMNS = [
    {
        title: 'Service ID',
        dataIndex: 'bookingNumericId',
        key: 'bookingNumericId',
        width: 140,
        render: (bookingNumericId: string, booking: any) => (
            <Link style={{ color: '#08d108' }} to={`/v2/booking?id=${booking._id}`}>
                {bookingNumericId}
            </Link>
        ),
    },
    {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 100,
        render: (createdAt: string) => (
            <div style={{ fontSize: 12, textAlign: 'center' }}>
                <div>
                    {moment(createdAt).format('DD/MM/YY')}
                </div>
                <div>
                    {moment(createdAt).format('HH:mm')}
                </div>
            </div>
        ),
    },
    {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'elapsedTime',
        width: 120,
        ellipsis: true,
        render: (createdAt: Date, record: BookingType) => {
            const booking = new BookingType(record);
            const minutes: any = booking.getMinutes();
            const isGreen = minutes < 6 || booking.statusText === 'COMPLETED';
            const color = isGreen ? '#08d108' : minutes < 11 ? 'orange' : 'red';
            return (
                <div style={{ color }}>
                    {`${minutes} min`}
                </div>
            );
        },
    },
    {
        title: 'Customer',
        dataIndex: 'customerName',
        key: 'customerName',
        ellipsis: true,
    },
    {
        title: 'Driver',
        dataIndex: 'driverName',
        key: 'driverName',
        ellipsis: true,
        render: (name: string, booking: any) => {
            if (!booking.driver) return name;
            return <Link to={`/v2/driver-details/${booking.driver._id}`}>{name}</Link>;
        },
    },
    {
        title: 'DP',
        key: 'deliveryProvider',
        dataIndex: 'deliveryProvider',
        width: 65,
        render: (provider: string) => (
            <Tooltip title={(PROVIDERS as any)[provider]?.label || ''}>
                <Avatar src={(PROVIDERS as any)[provider]?.image || ''} />
            </Tooltip>
        ),
    },
    {
        title: 'Business',
        dataIndex: 'businessName',
        key: 'businessName',
        ellipsis: true,
    },
    {
        title: 'Paym.',
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        width: 80,
        render: (paymentMethod: string) => (
            <Tooltip placement="topLeft" title={paymentMethod === 'CASH' ? 'Efectivo' : 'Tarjeta'}>
                {paymentMethod === 'CASH' ? <BsCash color="#08d108" size={20} /> : <BsCreditCard2Back color="#00beff" size={20} />}
            </Tooltip>
        ),
    },
    {
        title: 'Proof of Delivery',
        dataIndex: 'proofOfDeliveryType',
        key: 'proofOfDeliveryType ',
        width: 100,
        render: (proofOfDeliveryType : string, booking: any) => (
            <Tooltip placement="topLeft" title={getProofTitle(proofOfDeliveryType)}>
                {proofOfDeliveryType === 'PHOTO' && <AiFillCamera color={getProofColor(booking.isProofOfDeliveryApproved)} size={20} /> }
                {proofOfDeliveryType === 'CODE' && <AiOutlineFileWord color={getProofColor(booking.isProofOfDeliveryApproved)} size={20} /> }
                {(!proofOfDeliveryType || proofOfDeliveryType === 'NONE') && <AiFillCloseCircle color="#FF0000" size={20} />}
            </Tooltip>
        ),
    },
    {
        title: 'Status',
        dataIndex: 'statusText',
        key: 'statusText',
        render: (status: string) => (
            <Tag {...getStatusChipClass(status)}>{status}</Tag>
        ),
    },
    {
        title: '',
        dataIndex: 'statusText',
        key: 'actions',
        width: 60,
        fixed: 'right' as 'right',
        // eslint-disable-next-line react/display-name
        render: (_statusText: any, record: any) => (
            <Dropdown
                placement="bottomRight"
                overlay={(
                    <BookingMenu
                        booking={record}
                    />
                )}
                // @ts-ignore
                onClick={(e: any) => { e.stopPropagation(); }}
            >
                <div style={{ textAlign: 'center' }}>
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        <BsThreeDots size={25} />
                    </a>
                </div>
            </Dropdown>
        ),
    },
];

interface BookingsTableProps {
  bookings: {
    data: BookingType[];
    count: number;
    loading: boolean;
    paginator:{
      limit: number;
      page: number;
    }
  }
  getBookings: (paginator: any) => void;
}

export const BookingsTable = ({ bookings, getBookings }: BookingsTableProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        const page = Number(params.get('page')) || 1;
        const limit = Number(params.get('limit')) || 10;
        getBookings({ limit, page });
    }, []);

    const onPageChange = (page: number, pageSize: number) => {
        const params = new URLSearchParams({ limit: pageSize.toString(), page: page.toString() });
        setSearchParams(params);
        getBookings({ limit: pageSize, page });
    };

    const handleDate = (date: any) => {
        const { paginator } = bookings;
        getBookings({
            limit: paginator.limit,
            page: paginator.page,
            startDate: date ? date[0] : '',
            endDate: date ? date[1] : '',
        });
    };

    return (
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <Filters handleDate={handleDate} />
            <Table
                loading={bookings.loading}
                columns={COLUMNS}
                dataSource={bookings.data}
                rowClassName={(record: any, index) => (record.firstTimeOrder ? 'table-row-warning' : 'booking-row')}
                bordered
                scroll={{ x: 1500 }}
                pagination={{
                    total: bookings.count,
                    current: bookings.paginator.page,
                    pageSize: bookings.paginator.limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
                rowKey="_id"
            />
            <AssigningDriverManually />
            <ChangeStatusModal />
            <CommentModal />
            <RestartSearchModal />
            <CancelService />
            <ReportIssue />
        </Space>
    );
};
