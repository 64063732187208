import { useState, FC, useEffect } from 'react';
import { Col } from 'antd';
import { ISharedMonitor } from '../context/datadogMonitor.interface';
import Loading from '../../../widgets/Loading';

interface MonitorEmbedElementProps {
    monitor: ISharedMonitor;
}

const MonitorEmbedElement: FC<MonitorEmbedElementProps> = ({ monitor }) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleLoad = () => {
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
    }, [monitor]);

    return (
        <Col span={24} key={monitor._id}>
            {isLoading && <Loading />}
            <iframe
                src={monitor.publicUrl}
                width="100%"
                style={{ height: '75vh', display: isLoading ? 'none' : 'block' }}
                onLoad={handleLoad}
                title={`Monitor view for ${monitor.title || 'Dashboard'}`}
            />
        </Col>
    );
};

export default MonitorEmbedElement;
