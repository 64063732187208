import moment from 'moment';
import { IExpressDelivery } from '../../../interfaces/express-delivery.interface';

export const initialContext = {
    loading: false,
    loadingAction: false,
    expressDeliveries: [] as IExpressDelivery[],
    expressSelected: null,
    total: 0,
    paginator: {
        limit: 10,
        skip: 0,
    },
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    tab: null,
    searchParams: {},
    handleStatus: () => { },
    handleDate: () => { },
    handleWorkspaceUniqueID: () => { },
    setLoading: () => {},
    fetchExpressDeliveries: () => {},
    onPageChange: () => {},
    setExpressSelected: () => {},
    fetchBusinessWithED: () => {},
};
