/* eslint-disable prefer-destructuring */
import {
    createContext,
    FC,
    useEffect,
    useState,
} from 'react';
import { notification } from 'antd';
import { IContext, ISharedMonitor } from './datadogMonitor.interface';
import initialContextData from './datadogMonitor.initial';
import { getDatadogSharedDashboards } from '../../../api/analytics';

export const DatadogMonitorContext = createContext<IContext>(initialContextData);

export const DatadogMonitorProvider: FC = ({ children }) => {
    const [initialContext] = useState<IContext>(initialContextData);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [sharedMonitors, setSharedMonitors] = useState<ISharedMonitor[]>(initialContext.sharedMonitors);
    const [selectedMonitor, setSelectedMonitor] = useState<ISharedMonitor|null>(initialContext.selectedMonitor);
    const [isOpenInfo, setIsOpenInfo] = useState<boolean>(initialContext.isOpenInfo);

    const selectMonitor = (monitorId: string) => {
        const monitor = sharedMonitors.find((m) => m._id === monitorId);

        if (!monitor) {
            return;
        }
        localStorage.setItem('selectedMonitor', monitorId);
        setSelectedMonitor(monitor);
    };

    const fetchSharedMonitors = async () => {
        try {
            setLoading(true);
            const { data } = await getDatadogSharedDashboards();
            const monitors = data.data;
            setSharedMonitors(monitors);

            const selectedFromLocalStorage = localStorage.getItem('selectedMonitor');

            if (selectedFromLocalStorage) {
                const monitor = monitors.find((m: ISharedMonitor) => m._id.toString() === selectedFromLocalStorage.toString());
                if (monitor) setSelectedMonitor(monitor);
            }
        } catch (error) {
            console.log('Error fetching shared monitors', error);
            const message = (error as any)?.response?.data?.message || 'Error fetching shared monitors';
            notification.error({ message });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSharedMonitors();
    }, []);

    return (
        <DatadogMonitorContext.Provider
            value={{
                loading,
                sharedMonitors,
                selectedMonitor,
                isOpenInfo,
                selectMonitor,
                setIsOpenInfo,
            }}
        >
            {children}
        </DatadogMonitorContext.Provider>
    );
};
