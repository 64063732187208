import { EWorkspaceStatus } from '../../../types/workspaces';

type TGetWorkspaceStatus = {
     isDeleted: boolean;
     isSuspended: boolean;
}

const getWorkspaceStatus = ({ isDeleted, isSuspended }:TGetWorkspaceStatus) => {
    if (!isDeleted && !isSuspended) return EWorkspaceStatus.ACTIVE;

    if (isDeleted && !isSuspended) return EWorkspaceStatus.DELETED;

    if (!isDeleted && isSuspended) return EWorkspaceStatus.SUSPENDED;

    if (isDeleted && isSuspended) return EWorkspaceStatus.DELETED_SUSPENDED;
};

export {
    getWorkspaceStatus,
};
