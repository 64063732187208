import {
    Collapse, Space, Typography, Image,
    Row,
} from 'antd';

const { Text, Title } = Typography;

const items = [
    {
        key: '1',
        label: 'Gráficos Timeseries en Datadog',
        text: `Los gráficos Timeseries en Datadog muestran la evolución de una métrica a lo largo del tiempo. 
        Son ideales para monitorear tendencias y detectar patrones inusuales en el comportamiento de tus sistemas. 
        En el caso de monitorear endpoints, este tipo de gráficos te permitirá visualizar cuántas llamadas se han realizado 
        a cada uno de tus endpoints en un intervalo de tiempo definido, así como ver la proporción de peticiones exitosas y con error.`,
        list: {
            title: '¿Cómo interpretar un gráfico Timeseries?',
            elements: [
                `Call count: El gráfico de series temporales te mostrará la cantidad de llamadas a tus endpoints. 
                Puedes observar si el tráfico hacia los endpoints aumenta o disminuye en momentos específicos.`,

                `Success/Failure Rate: Con diferentes líneas o colores, podrás diferenciar entre llamadas exitosas y fallidas. 
                Esto te ayudará a identificar si hay un patrón recurrente de fallos o un comportamiento anómalo en el sistema.`,

                `Análisis temporal: Ajusta el intervalo de tiempo en la parte superior del gráfico para ver datos históricos o 
                analizar eventos recientes (por ejemplo, las últimas 24 horas o la última semana).`,
            ],
        },
        src: 'https://picker-dashboard.s3.amazonaws.com/images/Chart.png',
    },
    {
        key: '2',
        label: 'Gráficos Query Value en Datadog',
        text: `Los gráficos Query Value en Datadog muestran un único valor calculado a partir de una consulta sobre tus datos. 
        Son útiles para destacar métricas clave como el número total de peticiones exitosas o la cantidad de errores en un período de tiempo determinado.`,
        list: {
            title: '¿Cómo interpretar un gráfico Query Value?',
            elements: [
                `Call Count: Este gráfico muestra el número total de llamadas a un endpoint en el intervalo de tiempo seleccionado. 
                Es útil para obtener una visión rápida del tráfico hacia tus servicios.`,

                `Success Count y Error Count: Los gráficos de Query Value te permitirán ver rápidamente la cantidad de peticiones 
                exitosas y fallidas. Esto es útil para detectar si el número de errores está aumentando o si el sistema está funcionando correctamente.`,

                `Estado de salud en un vistazo: Un vistazo rápido a estos valores te dará una idea clara del rendimiento actual 
                de tus endpoints sin necesidad de revisar gráficos detallados.`,
            ],
        },
        src: 'https://picker-dashboard.s3.amazonaws.com/images/Total.png',
    },
    {
        key: '3',
        label: 'AVG Access Time en Datadog',
        text: `El AVG Access Time o tiempo promedio de acceso es una métrica clave para medir la latencia de tus endpoints. 
        Este valor representa el tiempo promedio que toma para que una solicitud sea procesada y respondida por el servicio. 
        Monitorear esta métrica es crucial para asegurar un buen rendimiento y una experiencia óptima para los usuarios.`,
        list: {
            title: '¿Cómo interpretar el AVG Access Time?',
            elements: [
                `Tiempo de respuesta promedio: Este valor muestra el tiempo promedio que toma tu endpoint en responder 
                a las solicitudes HTTP. Un aumento en este valor podría ser indicativo de problemas de rendimiento o alta carga en el sistema.`,

                `Umbrales de alerta: Configura alertas que te notifiquen cuando el tiempo de respuesta promedio supere cierto valor. 
                Esto es útil para detectar picos de latencia que podrían afectar la experiencia del usuario.`,

                `Análisis por endpoint: El query que estás usando (por ejemplo, @http.response_time over "service:BOOKING-SERVICE @http.url:/bookings") 
                te permitirá filtrar específicamente por servicio y endpoint, dándote una visión clara de cómo se comporta el tiempo de respuesta en diferentes 
                partes de tu sistema.`,

                `Tendencias y patrones: Observa cómo evoluciona el AVG Access Time a lo largo del tiempo para identificar 
                si hay períodos donde el tiempo de respuesta aumenta (por ejemplo, durante horas pico o después de un despliegue de código).`,
            ],
        },
        src: 'https://picker-dashboard.s3.amazonaws.com/images/AVG.png',
    },
    {
        key: '4',
        label: 'Call Count y Tasa de Éxito/Error',
        text: `Los dashboards de Datadog permiten visualizar la cantidad total de peticiones realizadas a tus endpoints y 
        separar las peticiones exitosas de las fallidas. Esto proporciona una visión clara del estado de tu sistema.`,
        list: {
            title: 'Indicadores clave:',
            elements: [
                `Call Count: Mide la cantidad total de peticiones realizadas a un endpoint. 
                Un aumento repentino en las llamadas puede indicar un pico en la demanda, mientras que una disminución podría señalar un problema.`,

                `Success Count: El número de peticiones que se completaron con éxito. 
                Un valor alto de peticiones exitosas es una buena señal, pero si el número de éxitos baja en comparación con el total de peticiones, 
                podría ser motivo de alerta.`,

                `Error Count: El número de peticiones que resultaron en un error. 
                Un aumento en los errores puede indicar un problema en el endpoint o en otros componentes de tu infraestructura.`,
            ],
        },
        src: null,
    },
];

const MonitorDrawerContent = () => (
    <Collapse accordion defaultActiveKey={['1']}>
        {items.map((item) => (
            <Collapse.Panel key={item.key} header={<Title level={5}>{item.label}</Title>}>
                <Space direction="vertical">
                    <Text>{item.text}</Text>
                    <Text strong>{item.list.title}</Text>
                    <ul>
                        {item.list.elements.map((element) => (
                            <li key={element}>{element}</li>
                        ))}
                    </ul>
                    {item.src && (
                        <Row justify="center">
                            <Image
                                src={item.src}
                                alt={item.label}
                                width="100%"
                                preview={false}
                            />
                        </Row>
                    )}
                </Space>
            </Collapse.Panel>
        ))}
    </Collapse>
);

export default MonitorDrawerContent;
