import { useContext } from 'react';
import {
    Collapse,
    Drawer,
    List,
    Space,
    Typography,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import Information from '../../ExpressDelivery/components/Information';
import { GuidesContext } from '../context/guides.context';
import DeliveryData from '../../ExpressDelivery/components/DeliveryData';
import { GuideOrderTable } from '../../ExpressDelivery/components/DeliveryOrderTable';
import MetaData from './MetaData';

const { Title } = Typography;
const { Panel } = Collapse;

const GuideDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { itemSelected, setItemSelected } = useContext(GuidesContext);

    if (!itemSelected) return null;

    return (
        <Drawer
            title={`Guide Detail - ${itemSelected.guideNumericId}`}
            open={!!itemSelected}
            onClose={() => {
                setItemSelected(null);
                const query = new URLSearchParams(location.search);
                query.delete('guideId');
                navigate(`/v2/express-delivery?${query.toString()}`);
            }}
            width={900}
        >
            <Space direction="vertical" size={40} style={{ width: '100%' }}>
                <Information
                    createdAt={itemSelected.createdAt}
                    businessName={itemSelected.businessName}
                    businessID={itemSelected.businessID}
                    workspaceID={itemSelected.workspaceID}
                    workspaceName={itemSelected.workspaceName}
                    deliveryProvider={itemSelected.deliveryProvider || 'Not assigned'}
                    driverName={itemSelected.driverName || 'Not assigned'}
                    driverMobile={itemSelected.driverMobile || 'Not assigned'}
                />
                <MetaData
                    header="Meta Data"
                    estimatedDeliveryTime={itemSelected.estimatedDeliveryTime || 0}
                    totalDistance={itemSelected.totalDistance || 0}
                    providerFee={itemSelected.providerFee || 0.00}
                />
                <Collapse
                    bordered={false}
                    ghost
                >
                    {
                        itemSelected.expressDeliveries.map((item, index) => {
                            const customer = `${item.customerName} ${item.customerLastName}`;

                            return (
                                <Panel
                                    key={index}
                                    header={(
                                        <Title level={5} style={{ marginBottom: 0 }}>
                                            {`${index + 1}º Point: ${item.expressNumericId} to ${customer}`}
                                        </Title>
                                    )}
                                >
                                    <DeliveryData
                                        description={item.description || '-'}
                                        customerCountryCode={item.customerCountryCode || '-'}
                                        customerMobile={item.customerMobile || '-'}
                                        weight={item.weight || 0}
                                        isFragile={item.isFragile || false}
                                        volume={item.volume || '-'}
                                        deliveryLocation={{
                                            address: item.deliveryAddress || '-',
                                            coordinates: item.deliveryCoordinates || { type: 'point', coordinates: [0, 0] },
                                            references: 'N/A' || '-',
                                        }}
                                        pickupLocation={{
                                            address: item.pickupAddress || '-',
                                            coordinates: item.pickupCoordinates || { type: 'point', coordinates: [0, 0] },
                                            references: 'N/A' || '-',
                                        }}
                                    />
                                </Panel>
                            );
                        })
                    }
                    {/* <List
                        dataSource={itemSelected.expressDeliveries}
                        renderItem={(item: any) => (
                            <List.Item key={item._id}>
                            </List.Item>
                        )}
                    /> */}
                </Collapse>
                <GuideOrderTable guideId={itemSelected._id} />
            </Space>
        </Drawer>
    );
};

export default GuideDetail;
