import PageHeader from 'antd/lib/page-header';
import { DatadogMonitorProvider } from './context/datadogMonitor.context';
import MonitorElementsContent from './components/MonitorElementsContent';
import MonitorUsageDrawer from './components/MonitorUsageDrawer';
import UsageButton from './components/UsageButton';

const FeaturesMonitors = () => (

    <DatadogMonitorProvider>
        <PageHeader
            title="Features Monitors"
            subTitle={<UsageButton />}
            footer={
                <MonitorElementsContent />
            }
        />
        <MonitorUsageDrawer />
    </DatadogMonitorProvider>

);

export default FeaturesMonitors;
