import { useState } from 'react';
import {
    Dropdown,
    Menu,
    Modal,
    notification,
    Space,
    Typography,
} from 'antd';
import { AiOutlineDown } from 'react-icons/ai';
import { editWorkspace } from '../../../../api/workspaces';

interface Props {
    workspaceId: string;
    payment?: {
        paymentGateway?: string;
        paymentMethod?: string;
    }
}

const { Text } = Typography;

const PaymentSelector = ({ workspaceId, payment }: Props) => {
    const [paymentMethod, setPaymentMethod] = useState<string | undefined>(payment?.paymentMethod);
    const PAYMENT_METHOD = ['BILLING_TRANSFER', 'BILLING_CARD', 'WALLET'];

    const confirmPaymentMethod = (paymentMethod: string) => Modal.confirm({
        title: 'Change payment method',
        content: `Are you sure you want to change the payment method to ${paymentMethod}?`,
        onOk: async () => {
            try {
                const payment = { paymentMethod };

                editWorkspace(workspaceId, payment)
                    .then(({ data }) => {
                        notification.success({
                            message: data.message,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        notification.error({ message: 'oops! An error has occurred' });
                    })
                    .finally(() => {
                        setPaymentMethod(paymentMethod);
                    });
            } catch (error) { console.log(error); }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    const menu = (
        <Menu>
            {
                PAYMENT_METHOD.map((payment: string) => (
                    <Menu.Item key={payment} onClick={() => confirmPaymentMethod(payment)}>
                        {payment}
                    </Menu.Item>
                ))
            }
        </Menu>
    );

    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
        >
            <a onClick={(e) => e.preventDefault()} style={{ color: 'white' }}>
                <Space size={4}>
                    <Text>
                        {paymentMethod}
                    </Text>
                    <AiOutlineDown
                        size={16}
                        style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                    />
                </Space>
            </a>
        </Dropdown>
    );
};

export default PaymentSelector;
