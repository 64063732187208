import { get } from 'lodash';
import { Menu, Modal, notification } from 'antd';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IExpressDelivery } from '../../../interfaces/express-delivery.interface';
import { ExpressDeliveryContext } from '../context/express-delivery.context';
import { cancelExpressDelivery } from '../../../api/express-delivery';

interface Props {
    expressDelivery: IExpressDelivery;
    setExpressSelected: (expressDelivery: IExpressDelivery) => void;
}

const { confirm } = Modal;

const TableMenu = ({ expressDelivery, setExpressSelected }: Props) => {
    const { fetchExpressDeliveries } = useContext(ExpressDeliveryContext);

    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    const cancelService = () => {
        confirm({
            title: 'Are you sure you want to cancel this service?',
            okText: 'Continue',
            cancelText: 'Cancel',
            onOk() {
                setLoading(true);
                cancelExpressDelivery(expressDelivery._id)
                    .then(() => {
                        notification.success({
                            message: 'Express delivery cancelled',
                        });
                        fetchExpressDeliveries();
                    })
                    .catch((error: any) => {
                        const description = get(error, 'response.data.message', '');
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    })
                    .finally(() => setLoading(false));
            },
            onCancel() {
                if (!loading) return;
            },
            okButtonProps: {
                loading,
            },
        });
    };

    const OPTIONS = [
        {
            children: 'View detail',
            onClick: () => {
                setExpressSelected(expressDelivery);
                const query = new URLSearchParams(location.search);
                query.set('expressDeliveryId', expressDelivery._id);
                navigate(`/v2/express-delivery?${query.toString()}`);
            },
        },
        {
            children: 'Cancel service',
            disabled: expressDelivery.status === 'CANCELLED',
            onClick: () => cancelService(),
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup
                key="g"
                title="Actions"
            >
                {
                    OPTIONS.map((option, index) => (
                        <Menu.Item key={index} {...option} />
                    ))
                }
            </Menu.ItemGroup>
        </Menu>
    );
};

export default TableMenu;
